import React from 'react';

import { SectionWrapper } from './styles';
import { TextSize } from '../types';
import GeneralLayout from '../layouts/GeneralLayout';
import Section from '../components/Section';
import SectionTitle from '../components/SectionTitle';
import { NavLinkStyleTypes } from '../types';
import { LayoutTypes } from '../types';
import NavigationLinkWithContext from '../components/NavigationLinkWithContext';
import { COMPANY_FULL_NAME } from '../shared/globals';
import { getMainMenuItems } from '../utils/nav-menu';
import BodyText from '../components/BodyText';

const TermsOfServicePage = () => {

  const navOptions = {
    ...getMainMenuItems(LayoutTypes.main),    
    isBgDark: false,
    isSideBgDark: false
  };

  return (
    <GeneralLayout {...navOptions}>
      <SectionWrapper>
        <Section id={'ca-notice-at-collection'} lightBg={true}>
          <SectionTitle
            topText={ 'January 2023' }
            headerText={ 'Terms Of Service' }
            textSize={ TextSize.middle }
          >
            <BodyText type='div'>
              <p class="w-100">COMMING SOON</p>
            </BodyText>
          </SectionTitle>
        </Section>
      </SectionWrapper>
    </GeneralLayout>
  );
}

export default TermsOfServicePage;
