import styled from 'styled-components';
import { respondTo } from '../mixins/responsive';
import { ViewHeightVariant } from '../../types';
import { hexToRgb } from '../../utils/colors';

const getColorString = (hexColor = '#e2e2e2', opacity = 0.3) => {
  if (hexColor && /^\s*$/.test(hexColor)) {
    hexColor = '#e2e2e2';
  }
  if (hexColor === 'transparent') {
    return hexColor;
  }
  const arrRgb = hexToRgb(hexColor);
  return `rgba(${arrRgb[0]}, ${arrRgb[1]}, ${arrRgb[2]}, ${opacity})`;
}

export const BoderShapeBlockWrapper = styled.div`
`;

export const ShapeBlockWrapper= styled.div`
  position: relative;
  background-color: ${({ color, opacity }) => getColorString(color, opacity)};
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  border-right: 30px;
  min-height: 300px;

  ${respondTo.xxs.standard`
    padding: 20px 20px 0 20px;
  `};

  ${respondTo.xs.standard`
    padding: 30px 30px 0 30px;
  `};

  ${respondTo.md.standard`
    padding: 50px 50px 0 50px;
  `};
`;

export const BoderShapeBlockContentWrapper= styled.div`
  position: relative;
  border-radius: 25px;
  min-height: 300px;
  background-color: var(--bs-body-bg);

  ${respondTo.xxs.standard`
    margin: -275px 25px 25px 25px;
    margin-bottom: 0;
    padding: 8px;
  `};

  ${respondTo.xs.standard`
    margin: -270px 30px 30px 30px;
    margin-bottom: 0;
    padding: 16px;
  `};

  ${respondTo.md.standard`
    margin: -250px 50px 50px 50px;
    margin-bottom: 50px;
    padding: 32px;
  `};
`;