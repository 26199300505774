import { LayoutTypes, ButtonVariant, NavLinkStyleTypes } from "../types";

export const getMainMenuItems = (layoutType = LayoutTypes.home) => {

  const baseUrl = '/'; //process.env.REACT_APP_SOPHINEX_DOMAIN_BASE_URL;

  return {
    baseUrl,
    menuItems: [
      {
        title: 'HOME',
        path: '/',
        isScrollNav: false 
      },
      {
        title: 'ABOUT US',
        path: '/about-us',
        isScrollNav: false 
      },
      {
        title: 'TESTIMONIALS',
        path: baseUrl,
        hash: 'testimonials'
      },
      {
        title: 'FAQ',
        path: `/faq`,
        isScrollNav: false 
      },
      {
        title: 'CONTACT US',
        path: '/contact-us',
        isScrollNav: false 
      }
    ],
    menuButton: {
      title: 'Get Free Offer',
      path: baseUrl,
      hash: 'get-offer',
      isExternalLink: false,
      isScrollNav: true, 
      style: NavLinkStyleTypes.button,
      variant: ButtonVariant.success,
      dark: false,
      alt: 'Get Free Offer'
    },
    layoutType
  };
};