import React from 'react';

import { LayoutTypes, TextSize, InfoBlockBgLinePosition, ContentFloat, NavLinkStyleTypes, ButtonVariant, ContentVerticalFloat, ViewHeightVariant } from '../types';
import { heroSlideData, infoBlockData, infoBlockData_2 } from '../data/mocked-content';
import { PageContentSectionBlock, PageContentBlock, PageContentAlignment, PageContentAlignmentCol } from './styles';

import GeneralLayout from '../layouts/GeneralLayout';
import BlockSection from '../components/BlockSection';
import ContainerWithBackground from '../components/ContainerWithBackground';
import ContainerGrid from '../components/ContainerGrid';
import InfoBlock from '../components/InfoBlock';
import Spacer from '../components/Spacer';
import BgShapeBlock from '../components/BgShapeBlock';
import BodyText from '../components/BodyText';
import SectionTitle from '../components/SectionTitle';
import NavigationLink from '../components/NavigationLink';
import NavigationLinkWithContext from '../components/NavigationLinkWithContext';
import CardBlock from '../components/CardBlock';
import NumeratedListItem from '../components/NumereatedListItem';
import BuyingOfferMultiStepForm from '../components/BuyingOfferMultiStepForm';
import { FormContextProvider } from '../context/FormContext';
import WithFormContext from '../components/WithFormContext';
import WhatWeCanDoComponent from '../components/WhatWeCanDo';
import Image from '../components/Image';

const ComponentsPage = () => {

  const baseUrl = process.env.REACT_APP_BUYING_HOUSE_DOMAIN_BASE_URL;
  const baseParentUrl = process.env.REACT_APP_SOPHINEX_DOMAIN_BASE_URL;
  const navOptions = {
    baseUrl,
    baseParentUrl,
    layoutType: LayoutTypes.subdom,
    isBgDark: false,
    isSideBgDark: false
  };

  return (
    <GeneralLayout { ...navOptions }>

      <ContainerWithBackground 
        bgImage={ '31179995_10.jpg' }
        bgColor={ '#e2e2e2' } 
        bgOverlayColor={ '#ffffff'} 
        bgOverlayTransparency={ 0.6 }
        heightVariant={ ViewHeightVariant.viewport }
      >
        <BlockSection {...heroSlideData} textSize={ TextSize.large } highlightContent={ true } bgImagee={ 'svg-shape.svg' } />
      </ContainerWithBackground>

      <Spacer></Spacer>

      <ContainerGrid id={ 'test' }>
            <BlockSection {...heroSlideData} topLine={ 'Block Section' } textSize={ TextSize.large } />
      </ContainerGrid>

      <Spacer></Spacer>

      <ContainerGrid isFullWidth={ true }>
        <ContainerWithBackground
            bgImage={ '31179995_10.jpg' }
            bgColor={ '#e2e2e2' } 
            bgOverlayColor={ '#000000'} 
            bgOverlayTransparency={ 0.5 }
            takeFullViewport ={ false }
        >
          <ContainerGrid>
            <PageContentBlock>
              <Spacer size={ 'lg' }></Spacer>
              <h1>PLACEHOLDER 3</h1>
              <Spacer size={ 'lg' }></Spacer>
            </PageContentBlock>
          </ContainerGrid>
        </ContainerWithBackground>
      </ContainerGrid>

      <Spacer></Spacer>

      <ContainerGrid>
        <PageContentBlock>
            <InfoBlock {...infoBlockData_2} />
        </PageContentBlock>
      </ContainerGrid>

      <Spacer></Spacer>
      
      <ContainerGrid>
        <PageContentSectionBlock>
          <InfoBlock {...infoBlockData} />
        </PageContentSectionBlock>
      </ContainerGrid>

      <Spacer></Spacer>

      <ContainerGrid>
        <PageContentBlock>
            <BgShapeBlock shapePos={ InfoBlockBgLinePosition.sideBottom } float={ ContentFloat.left } baseZIndex={ 0 }>
                <BodyText type='div' alignment='justify'>
                    <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus tempus nisl ut suscipit tempus. Vestibulum dolor nunc, ultrices ac pretium euismod, efficitur at felis. Ut placerat pulvinar dui. Fusce quis lacinia est. Duis venenatis urna a ultrices efficitur. Vestibulum luctus quis elit in luctus. Suspendisse efficitur nec odio eget rutrum. Sed rutrum nibh volutpat volutpat malesuada. Donec eu semper sapien. Suspendisse eu suscipit mauris.</span>
                </BodyText>
            </BgShapeBlock>
        </PageContentBlock>
      </ContainerGrid>

      <Spacer></Spacer>

      <ContainerGrid>
        <PageContentBlock>
            <BgShapeBlock shapePos={ InfoBlockBgLinePosition.half } float={ ContentFloat.left } baseZIndex={ 0 }>
                <SectionTitle
                    topText={ '' }
                    headerText={ 'Mauris eu dignissim vehicula' }
                    textSize={ TextSize.middle }
                >
                    <BodyText type='div' alignment='justify'>
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus tempus nisl ut suscipit tempus. Vestibulum dolor nunc, ultrices ac pretium euismod, efficitur at felis. Ut placerat pulvinar dui. Fusce quis lacinia est. Duis venenatis urna a ultrices efficitur. Vestibulum luctus quis elit in luctus. Suspendisse efficitur nec odio eget rutrum. Sed rutrum nibh volutpat volutpat malesuada. Donec eu semper sapien. Suspendisse eu suscipit mauris.</span>
                    </BodyText>
                    <Spacer size='sm'></Spacer>
                    <PageContentAlignment align={ ContentFloat.right } noGap={ true }>
                        <NavigationLinkWithContext 
                            title={ 'Go Up' } 
                            path={ '/components' } 
                            hash={ 'test' } 
                            isExternalLink={ false } 
                            style={ NavLinkStyleTypes.button }
                            className='inverted' 
                            variant={ ButtonVariant.success }
                            dark={ false } 
                            alt={ 'Vivamus' } />
                        <div style={{ width: '24px', height: '24px'}}></div>
                        <NavigationLink 
                            title={ 'Contact Us' } 
                            to={ '/contact-us' }
                            style={ NavLinkStyleTypes.button } 
                            isExternalLink={ false } 
                            isScrollNav={ false } 
                            className='inverted' 
                            variant={ ButtonVariant.primary }
                            dark={ true }
                            alt='Vivamus' />
                    </PageContentAlignment>
                </SectionTitle>
            </BgShapeBlock>
        </PageContentBlock>
      </ContainerGrid>

      <Spacer></Spacer>

      <ContainerGrid>
        <PageContentBlock>
            <BgShapeBlock shapePos={ InfoBlockBgLinePosition.side } float={ ContentFloat.right } baseZIndex={ 0 }>
                <SectionTitle
                    topText={ '' }
                    headerText={ 'Mauris eu dignissim vehicula' }
                    textSize={ TextSize.middle }
                    headerFloat={ ContentFloat.right }
                >
                    <BodyText type='div' alignment={ 'justify' }>
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus tempus nisl ut suscipit tempus. Vestibulum dolor nunc, ultrices ac pretium euismod, efficitur at felis. Ut placerat pulvinar dui. Fusce quis lacinia est. Duis venenatis urna a ultrices efficitur. Vestibulum luctus quis elit in luctus. Suspendisse efficitur nec odio eget rutrum. Sed rutrum nibh volutpat volutpat malesuada. Donec eu semper sapien. Suspendisse eu suscipit mauris.</span>
                    </BodyText>
                    <Spacer size='sm'></Spacer>
                    <PageContentAlignment align={ ContentFloat.right } noGap={ true }>
                        <NavigationLinkWithContext 
                            title={ 'Go Up' } 
                            path={ '/components' } 
                            hash={ 'test' } 
                            isExternalLink={ false } 
                            style={ NavLinkStyleTypes.button }
                            className='inverted' 
                            variant={ ButtonVariant.success }
                            dark={ false } 
                            alt={ 'Vivamus' } />
                        <div style={{ width: '24px', height: '24px'}}></div>
                        <NavigationLink 
                            title={ 'Contact Us' } 
                            to={ '/contact-us' }
                            style={ NavLinkStyleTypes.button } 
                            isExternalLink={ false } 
                            isScrollNav={ false } 
                            className='inverted' 
                            variant={ ButtonVariant.primary }
                            dark={ false }
                            alt='Vivamus' />
                    </PageContentAlignment>
                </SectionTitle>
            </BgShapeBlock>
        </PageContentBlock>
      </ContainerGrid>

      <Spacer></Spacer>

      <ContainerGrid>
        <PageContentBlock>
            <div className='row'>
                <div className='col-xs-12 col-md-12 col-lg-6'>
                    <BgShapeBlock shapePos={ InfoBlockBgLinePosition.side } float={ ContentFloat.left } fullHeight={ true } baseZIndex={ 0 }>
                        <SectionTitle
                            topText={ 'Vivamus tempus' }
                            headerText={ 'Mauris eu dignissim vehicula' }
                            textSize={ TextSize.large }
                            headerFloat={ ContentFloat.left }
                        >
                            <BodyText type='div' alignment={ 'justify' }>
                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus tempus nisl ut suscipit tempus. Vestibulum dolor nunc, ultrices ac pretium euismod, efficitur at felis. Ut placerat pulvinar dui.</span>
                            </BodyText>
                            <Spacer size='sm'></Spacer>
                            <PageContentAlignment align={ ContentFloat.right } noGap={ true }>
                                <NavigationLinkWithContext 
                                    title={ 'Vivamus' } 
                                    path={ '/components' } 
                                    hash={ 'test' } 
                                    isExternalLink={ false } 
                                    style={ NavLinkStyleTypes.button }
                                    className='inverted-' 
                                    variant={ ButtonVariant.success }
                                    dark={ false } 
                                    alt={ 'Vivamus' } />
                            </PageContentAlignment>
                        </SectionTitle>
                    </BgShapeBlock>
                </div>
                <div className='col-xs-12 col-md-12 col-lg-6'>
                    <div className='row pt-4'>
                        <div className='col-xs-12 col-md-6 col-lg-6 p-0'>
                            <CardBlock title={ 'Lorem ipsum dolor' } subTitle={ 'TEST' } bgColor={ '#F1F3D8' } borderColor={ '#F1F3D8' } minHeight={ '250px' }>
                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
                            </CardBlock>
                        </div>
                        <div className='col-xs-12 col-md-6 col-lg-6 p-0'>
                            <CardBlock title={ 'Lorem ipsum dolor' } subTitle={ 'TEMPUS' } bgColor={ '#F1F3D8' } borderColor={ '#F1F3D8' } minHeight={ '250px' }>
                                <span>Vivamus tempus nisl ut suscipit tempus.</span>
                            </CardBlock>
                        </div>
                        <div className='col-xs-12 col-md-6 col-lg-6 p-0'>
                            <CardBlock title={ 'Lorem ipsum dolor' } subTitle={ 'DOLOR' } bgColor={ '#F1F3D8' } borderColor={ '#F1F3D8' } minHeight={ '250px' }>
                                <span>Vestibulum dolor nunc, ultrices ac pretium euismod.</span>
                            </CardBlock>
                        </div>
                        <div className='col-xs-12 col-md-6 col-lg-6 p-0'>
                            <CardBlock title={ 'Lorem ipsum dolor' } subTitle={ 'PRETIUM' } bgColor={ '#F1F3D8' } borderColor={ '#F1F3D8' } minHeight={ '250px' }>
                                <span>Ultrices ac pretium euismod, efficitur at felis.</span>
                            </CardBlock>
                        </div>
                    </div>
                </div>
            </div>
        </PageContentBlock>
      </ContainerGrid>

      <Spacer></Spacer>

      <ContainerGrid isFullWidth={ true }>
        <ContainerWithBackground
            bgImage={ '31179995_10.jpg' }
            bgColor={ '#e2e2e2' } 
            bgOverlayColor={ '#000000'} 
            bgOverlayTransparency={ 0.5 }
            takeFullViewport ={ false }
        >
            <ContainerGrid>
                <PageContentBlock className='p-5'>
                    <SectionTitle
                        headerText={ 'Mauris eu dignissim vehicula' }
                        textSize={ TextSize.large }
                        headerFloat={ ContentFloat.center }
                        textFloat={ ContentFloat.center }
                        isVertical={ true }
                        align={ ContentFloat.center }
                    >
                        <BodyText type='div' alignment={ ContentFloat.center }>
                            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus tempus nisl ut suscipit tempus.</span>
                        </BodyText>
                        <Spacer size='sm'></Spacer>
                        <div className='row'>
                            <div className='col-xs-12 col-md-6 col-lg-6 p-0'>
                                <div className='row'>
                                    <div className='col-xs-12 col-md-12 col-lg-12 p-4'>
                                        <NumeratedListItem itemNum={ '01' } color={ '#6382AA' } title={ 'Item 1' } text={ 'Consectetur adipiscing elit nisl ut suscipit tempus.'} />
                                    </div>
                                    <div className='col-xs-12 col-md-12 col-lg-12 p-4'>
                                        <NumeratedListItem itemNum={ '02' } color={ '#E37B7B' } title={ 'Item 2' } text={ 'Vivamus tempus nisl ut suscipit tempus.'} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-xs-12 col-md-6 col-lg-6 p-0'>
                                <div className='row'>
                                    <div className='col-xs-12 col-md-12 col-lg-12 p-4'>
                                        <NumeratedListItem itemNum={ '03' } color={ '#93BC93' } title={ 'Item 3' } text={ 'Tempus nisl ut suscipit tempus.'} />
                                    </div>
                                    <div className='col-xs-12 col-md-12 col-lg-12 p-4'>
                                        <NumeratedListItem itemNum={ '04' } color={ '#949596' } title={ 'Item 4' } text={ 'Consectetur tempus nisl ut suscipit tempus.'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Spacer size='sm'></Spacer>
                        <PageContentAlignment align={ ContentFloat.center } noGap={ true }>
                            <NavigationLinkWithContext 
                                title={ 'Vivamus' } 
                                path={ '/components' } 
                                hash={ 'test' } 
                                isExternalLink={ false } 
                                style={ NavLinkStyleTypes.button }
                                className='inverted-' 
                                variant={ ButtonVariant.success }
                                dark={ false } 
                                alt={ 'Vivamus' } />
                        </PageContentAlignment>
                    </SectionTitle>
                </PageContentBlock>
            </ContainerGrid>
        </ContainerWithBackground>
      </ContainerGrid>

      <Spacer></Spacer>

      <ContainerGrid>
        <PageContentBlock>
            <WhatWeCanDoComponent>
                <InfoBlock {...infoBlockData_2} />
                <br/><br/>
                <FormContextProvider>
                    <WithFormContext render={(propsWithContext) => <BuyingOfferMultiStepForm { ...propsWithContext }></BuyingOfferMultiStepForm>} />
                </FormContextProvider>
            </WhatWeCanDoComponent>
        </PageContentBlock>
      </ContainerGrid>

      <Spacer></Spacer>
      
      <ContainerGrid>
        <PageContentSectionBlock>
            <BgShapeBlock shapePos={ InfoBlockBgLinePosition.none } float={ ContentFloat.center } fullHeight={ true } addContentSideOffset={ false } baseZIndex={ 0 }>
            <div className='row g-3'>
                <div className='col-xs-12 col-md-6 col-lg-6 p-0'>
                    <ContainerWithBackground 
                        bgColor={ '#e2e2e2' } 
                        bgOverlayColor={ '#ffffff'} 
                        bgOverlayTransparency={ 0.3 }
                        heightVariant={ ViewHeightVariant.auto }
                        className='p-5'
                    >
                        <SectionTitle
                            topText={ 'Vivamus tempus' }
                            headerText={ 'Mauris eu dignissim vehicula' }
                            textSize={ TextSize.large }
                            headerFloat={ ContentFloat.left }
                        >
                            <BodyText type='div' alignment={ 'justify' }>
                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus tempus nisl ut suscipit tempus. Vestibulum dolor nunc, ultrices ac pretium euismod, efficitur at felis. Ut placerat pulvinar dui.</span>
                            </BodyText>
                            <Spacer size='sm'></Spacer>
                            <PageContentAlignment align={ ContentFloat.right } noGap={ true }>
                                <NavigationLinkWithContext 
                                    title={ 'Vivamus' } 
                                    path={ '/components' } 
                                    hash={ 'test' } 
                                    isExternalLink={ false } 
                                    style={ NavLinkStyleTypes.button }
                                    className='inverted-' 
                                    variant={ ButtonVariant.success }
                                    dark={ false } 
                                    alt={ 'Vivamus' } />
                            </PageContentAlignment>
                        </SectionTitle>
                    </ContainerWithBackground>
                </div>
                <div className='col-1 p-0'></div>
                <div className='col-xs-12 col-md-5 col-lg-5 p-0'>
                    <PageContentAlignment align={ ContentFloat.center } noGap={ true }>
                        <Image img={ 'face-to-face-bro-people.svg' } bgImage={ 'svg-shape.svg' }></Image>
                    </PageContentAlignment>
                </div>
            </div>
            </BgShapeBlock>
        </PageContentSectionBlock>
      </ContainerGrid>

        <Spacer></Spacer>

        <ContainerWithBackground 
            bgImage={ '31179995_10.jpg' }
            bgColor={ '#e2e2e2' } 
            bgOverlayColor={ '#ffffff'} 
            bgOverlayTransparency={ 0.6 }
            heightVariant={ ViewHeightVariant.viewport }
        >   
            <div className='py-6 h-100' style={{ position: 'absolute', top: '0', right: '0', width: '50%', paddingLeft: '32px'}}>
                <PageContentAlignment align={ ContentFloat.right } vAlign={ ContentVerticalFloat.center } fullHeight={ true } noGap={ true }>
                    <Image img={ 'for-sale-pana-building.svg' } bgImagee={ 'svg-shape.svg' } style={{zIndex: '4'}}></Image>
                </PageContentAlignment>
            </div>

            <ContainerGrid className='h-100'>
                <PageContentAlignment align={ ContentFloat.left } vAlign={ ContentVerticalFloat.center } fullHeight={ true } noGap={ true }>
                    <ContainerWithBackground 
                        bgColor={ '#ffffff' } 
                        bgOverlayTransparency={ 0.35 }
                        className='p-5'
                        style={{ width: '50%'}}
                    >
                        <SectionTitle
                            topText={ 'Vivamus tempus' }
                            headerText={ 'Mauris eu dignissim vehicula' }
                            textSize={ TextSize.large }
                            headerFloat={ ContentFloat.left }
                        >
                            <BodyText type='div' alignment={ 'justify' }>
                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus tempus nisl ut suscipit tempus. Vestibulum dolor nunc, ultrices ac pretium euismod, efficitur at felis. Ut placerat pulvinar dui.</span>
                            </BodyText>
                            <Spacer size='sm'></Spacer>
                            <PageContentAlignment align={ ContentFloat.right } noGap={ true }>
                                <NavigationLinkWithContext 
                                    title={ 'Vivamus' } 
                                    path={ '/components' } 
                                    hash={ 'test' } 
                                    isExternalLink={ false } 
                                    style={ NavLinkStyleTypes.button }
                                    className='inverted-' 
                                    variant={ ButtonVariant.success }
                                    dark={ false } 
                                    alt={ 'Vivamus' } />
                            </PageContentAlignment>
                        </SectionTitle>
                    </ContainerWithBackground>
                </PageContentAlignment>
            </ContainerGrid>
        </ContainerWithBackground>

        <Spacer size={ 'sm' }></Spacer>

    </GeneralLayout>
  );
}

export default ComponentsPage;
