import styled from 'styled-components';
import HeaderText from '../HeaderText';

export const NumeratedListItemWrapper = styled.div`
  & h5 {
    padding-bottom: 16px;
  }
`;

export const NumeratedListItemNumber = styled(HeaderText)`
  color: ${({ color }) => color };
`;