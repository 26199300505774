export const heroSlideAboutUs = {
    id: 'slide_buying_homes',
    lightBg: false,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    headline: 'Sell Your House With Us',
    description: 'We use creative approach to deal with property sellers and it sets us apart from others.',
    buttonLabel: 'Learn More',
    imgStart: false,
    img: require('../images/efficiency-amico-business.svg'),
    imgOverlay: true,
    imgOverlayTransparency: 0.3,
    imgSourceHref: 'https://storyset.com/business',
    imgSourceTitle: 'Business illustrations by Storyset',
    imgSourceIntro: 'illustration by storyset',
    imgSourceColor: 'on-light',
    imgSourceLocation: 'relative',
    imgSourceAlignment: 'bottom-center',
    imgSourceVerticalOffset: 0,
    imgSourceShowIcon: false,
    bgColor: '',
    alt: 'Learn More',
    dark: true,
    primary: true,
    darkText: true,
    transparent: true,
    inverted: false,
    to: 'we-are-different'
  };