import { COMPANY_NAME } from "../../shared/globals";
export const Testimonials = [
    {
        id: '0',
        fullName: 'Craig J.',
        body: `My family facing foreclosure and wasn\’t enough time to sell the house using a real estate agent service. Thank you so much ${ COMPANY_NAME } for stopped the foreclosure, saved my credit and bought my house.`,
        image: 'website-creator-bro-people.svg',
        date: '-- Arizona',
        alt: ''
    },
    {
        id: '1',
        fullName: 'Alex S.',
        body: `I was in foreclosure situation and received a letter in my mailbox, gave a call to ${ COMPANY_NAME } and got an excellent service. Guys were very polite and easy to work with, took quick actions. Your company helped me to save my credit, paid my equity and we closed in 15 days. I definitely recommend ${ COMPANY_NAME } service to my friends and family members.`,
        image: 'website-creator-bro-people.svg',
        date: '-- Utah',
        alt: ''
    },
    /*{
        id: '2',
        fullName: 'Cecilia G.',
        body: 'I\'ve been a client of Sophinex Investment and would highly recommend their services. They have assisted me with claiming for state funds after forclosure and I have been more than happy with the results.',
        image: 'website-creator-bro-people.svg',
        date: '-- Arizona',
        alt: ''
    },
    {
        id: '3',
        fullName: 'Dave S.',
        body: 'I\'d like to express my thanks for the work you have done for us. The expertise and attention to detail with which you have handled our financial affairs could not be faulted. Particularly reassuring to us is our feeling of certainty in the absolute integrity of your dealings with us. May you prosper, along with your clients.',
        image: 'website-creator-bro-people.svg',
        date: '-- Arkansas',
        alt: ''
    }*/
];