export const COMPANY_NAME = 'Sophinex Investment';
export const COMPANY_FULL_NAME = `${COMPANY_NAME}, LLC`;
export const COMPANY_BUSINESS_HOURS_1 = 'Mon–Fri 9:00 am – 5:00 pm';
export const COMPANY_BUSINESS_HOURS_2 = 'Sat 10:00 am – 3:00 pm';
export const COMPANY_BUSINESS_HOURS_TZ = 'Pacific Time';
export const COMPANY_PHONE = '1-877-710-6030';
export const COMPANY_PHONE_ALT = '(877) 710-6030';
export const COMPANY_FAX = '1-877-710-6030';
export const COMPANY_EMAIL = 'info@sophinex.com';
export const COMPANY_ADDRESS = '1650 Garnet Ave';
export const COMPANY_ADDRESS_2 = 'Ste. 1042';
export const COMPANY_CITY = 'San Diego';
export const COMPANY_STATE = 'CA';
export const COMPANY_POSTAL_CODE = '92109';
export const COMPANY_DESCRIPTION = `${ COMPANY_NAME } is a privately held and operated company with a great vision, expertise and experience of doing smart investments in real estate.`;
export const COMPANY_SLOGAN = 'As a privately held and operated business, we work hard to build trust and strong relationships with all of our clients and partners. That is why we remain at the top of our game.';

export const SERVICE_STATES = 'Arizona, Arkansas, California, Florida, Georgia, Idaho, Kansas, Nevada, Oklahoma, Tennessee, Texas, Utah, Washington and Wyoming';
export const SERVICE_STATES_SHORT = 'AZ, AR, CA, FL, GA, ID, KS, NV, OK, TN, TX, UT, WA and WY';

export const COPYRIGHT = `© 2023 ${COMPANY_FULL_NAME}. All rights reserved.`;