import styled from 'styled-components';
import { respondTo } from '../mixins/responsive';

export const FormWrapper = styled.div`
    border-radius: 16px;
`;

export const Form = styled.form`
`;

export const FormFooterButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  flex-wrap: wrap;

  & button {
    min-width: 130px;

    ${respondTo.xxs.standard`
      width: 100%;
    `};
    ${respondTo.xs.standard`
      width: 100%;
    `};
    ${respondTo.sm.standard`
      width: auto;
    `};
  }
`;

export const FormFooterContent = styled.div`
`;

export const FormSubmitContent = styled.div`
`;