import React, { createContext, useState } from 'react';

export const FormContext = createContext();

export const FormContextProvider = ({ children }) => {

  const [currentStep, setCurrentStep] = useState(1);
  const [formValues, setFormValues] = useState(null);
  const value = { currentStep, setCurrentStep, formValues, setFormValues };

	return (
		<FormContext.Provider value={ value }>
			{children}
		</FormContext.Provider>
	)
}