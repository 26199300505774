import React, { useContext } from 'react';
import { FormContext } from '../../context/FormContext';

const WithFormContext = (props) => {

  const formContext = useContext(FormContext);
  const propsWithContex = { ...props, ...formContext };

  return (
    <>
      { props.render(propsWithContex) }
    </>
  );
};
  
export default WithFormContext;