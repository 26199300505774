import React from 'react';
import { BoderShapeBlockWrapper, ShapeBlockWrapper, BoderShapeBlockContentWrapper } from './styles';
import { BoderShapeVariant } from '../../types';

const BoderShapeBlock = (props) => {

  const {
    children,
    variant = BoderShapeVariant.left_top_right,
    color,
    opacity
  } = props;

  return (
    <BoderShapeBlockWrapper { ... props }>
      <ShapeBlockWrapper color={ color } opacity={ opacity } variant={ variant } />
      <BoderShapeBlockContentWrapper>
        { children }
      </BoderShapeBlockContentWrapper>
    </BoderShapeBlockWrapper>
  );
};

export default BoderShapeBlock;
