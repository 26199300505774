import styled from 'styled-components';

export const TopShape = styled.div`
    position: relative;
    padding: 50px 50px 0 50px;
    background-color: rgba(0,0,0, .05);
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    border-right: 30px;
    height: 300px;
`;

export const Body = styled.div`
    margin: -250px 50px 50px 50px;
    position: relative;
    padding: 30px;
    border-radius: 25px;
    min-height: 300px;
    background-color: var(--bs-body-bg);

    @media screen and (max-width: 768px) {
        margin-bottom: 0;
    }
`;
