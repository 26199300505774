import React, { useState , useEffect} from 'react';
import emailjs from '@emailjs/browser';
import { ToastService } from '../../services/toast.service';
import { BuyingOfferMultiStepFormWrapper, BuyingOfferMultiStepFormContent } from './styles';
import { ButtonVariant } from '../../types';
import validate from '../../utils/validation';
import DynamicForm from '../DynamicForm';

const DEFAULT_STATE = {
  currentStep: 1,
  propertyAddress: '',
  propertyType: '',
  propertyApproxValue: '',
  howSoonToSell: '',
  sellerFullName: '',
  sellerEmail: '',
  sellerPhone: ''
};

const EMAIL_TEMPLATE_ID = 'general_template';

const BuyingOfferMultiStepForm = (props) => {

  const {
    currentStep, 
    setCurrentStep, 
    formValues, 
    setFormValues
  } = props;

  const formProps = {
    currentStep, 
    setCurrentStep, 
    formValues, 
    setFormValues
  };

  const formStepConfigs = [
    {
      submitVariant: ButtonVariant.success,
      isValid: false,
      controls: {
        propertyAddress: {
          type: 'input',
          name: 'propertyAddress',
          value: '',
          label: 'What is your property address?',
          placeholder: 'Property address',
          valid: false,
          touched: false,
          validationRules: {
            required: true,
            minLength: 20,
            maxLength: 150
          },
          validationError: {
            required: 'Property address is required',
            minLength: 'Invalid property address',
            maxLength: 'Invalid property address'
          }
        }
      }
    },
    {
      title: 'Step 2',
      subTitle: 'Give us a type of your property.',
      titleColor: '#51865e',
      submitVariant: ButtonVariant.success,
      isValid: false,
      controls: {
        propertyType: {
          type: 'radio',
          name: 'propertyType',
          label: 'Property type',
          value: '',
          valid: false,
          touched: false,
          options: [
            { label: 'Single Family House', value: 'Single Family House' },
            { label: 'Townhouse', value: 'Townhouse' },
            { label: 'Condominium', value: 'Condominium' },
            { label: 'Mobile House', value: 'Mobile House' },
            { label: 'Commercial', value: 'Commercial' }
          ],
          validationRules: {
            required: true
          },
          validationError: {
            required: 'Select property type'
          }
        }
      }
    },
    {
      title: 'Step 3',
      subTitle: 'Give us your property approximate value.',
      titleColor: '#51865e',
      submitVariant: ButtonVariant.success,
      isValid: false,
      controls: {
        propertyApproxValue: {
          type: 'radio',
          name: 'propertyApproxValue',
          value: '',
          label: 'Property value',
          valid: false,
          touched: false,
          options: [
            { label: '$665K or less', value: '<$665K' },
            { label: '$665K- $935K', value: '$665K - $935K' },
            { label: '$935K - 1.3M', value: '$935K - 1.3M' },
            { label: '$1.3M - $1.7M', value: '$1.3M - $1.7M' },
            { label: '$1.7M - $4.4M', value: '$1.7M - $4.4M' },
            { label: '$4.4M or more', value: '$4.4M+' }
          ],
          validationRules: {
            required: true
          },
          validationError: {
            required: 'Select property approximate value'
          }
        }
      }
    },
    {
      title: 'Step 4 - Almost done',
      subTitle: 'How soon are you looking to sell your property?',
      titleColor: '#51865e',
      submitVariant: ButtonVariant.success,
      isValid: false,
      controls: {
        howSoonToSell: {
          type: 'radio',
          name: 'howSoonToSell',
          value: '',
          label: 'Time frame',
          valid: false,
          touched: false,
          options: [
            { label: 'ASAP', value: 'ASAP' },
            { label: '1 - 3 months', value: '1 - 3 months' },
            { label: '3 - 6 months', value: '3 - 6 months' },
            { label: '6 - 12 months', value: '6 - 12 months' },
            { label: '12+ months', value: '12+ months' }
          ],
          validationRules: {
            required: true
          },
          validationError: {
            required: 'Select time frame'
          }
        }
      }
    },
    {
      title: 'What is the best way to contact you?',
      titleColor: '#51865e',
      footerText: 'Our services are 100% free, and we do not share your information.',
      showSubmitText: true,
      submitLabel: 'Get Free Offer',
      submitVariant: ButtonVariant.success,
      isValid: false,
      controls: {
        sellerFullName: {
          type: 'input',
          name: 'sellerFullName',
          value: '',
          label: 'Seller\'s Full Name',
          placeholder: 'Full Name',
          valid: false,
          touched: false,
          validationRules: {
            required: true,
            minLength: 2,
            maxLength: 150
          },
          validationError: {
            required: 'Full name is required.',
            minLength: 'Invalid full name.',
            maxLength: 'Invalid full name.'
          }
        },
        sellerEmail: {
          type: 'input',
          name: 'sellerEmail',
          value: '',
          label: 'Seller\'s e-mail address',
          placeholder: 'E-mail address',
          valid: false,
          touched: false,
          validationRules: {
            required: true,
            email: true
          },
          validationError: {
            required: 'E-mail address is required',
            email: 'Invalid e-mail address'
          }
        },
        sellerPhone: {
          type: 'input',
          name: 'sellerPhone',
          value: '',
          label: 'Seller\'s phone number',
          placeholder: 'Phone number',
          valid: false,
          touched: false,
          validationRules: {
            required: true,
            phone: true
          },
          validationError: {
            required: 'Phone number is required',
            phone: 'Invalid phone number'
          }
        }
      }
    }
  ];

  const [formConfigState, setFormConfigState] = useState(formStepConfigs[0]);

  const isFormConfigValuesValid = (config = formConfigState) => {
    let formIsValid = false;
    for(let controlName in config?.controls) {
      const ctrl = config?.controls[controlName];
      formIsValid = typeof validate(ctrl?.value, ctrl?.validationRules) === 'boolean';
      if (!formIsValid) {
        break;
      }
    };
    return formIsValid;
  }

  const syncConfigWithFormValues = (config) => {
    if (!config) {
      return;
    }
    const updatedControls = {
      ...config.controls
    };

    Object.keys(updatedControls).forEach((controlName) => {
      updatedControls[controlName].value = formValues && formValues[controlName] || '';
    });

    let result = {
      ...config,
      controls: { ...updatedControls }
    };
    result.isValid = isFormConfigValuesValid(result);

    return result;
  }

  const sendEmail = (data, cb) => {
    emailjs.send("service_1hded7m", EMAIL_TEMPLATE_ID, {
      from_name: data?.sellerFullName,
      message: `Here is a free offer request.\n\nProperty address: ${data?.propertyAddress}\nProperty type: ${data?.propertyType}\nProperty approx. value: ${data?.propertyApproxValue}\nSelling time frame: ${data?.howSoonToSell}`,
      from_phone: data?.sellerPhone,
      from_email: data?.sellerEmail 
    }).then((result) => {
      ToastService.success({ message: 'Your request for the free offer has been sent successfuly. We will contact you shortly.', autohide: true, keepAfterRouteChange: true });
      typeof cb === 'function' && cb();
    }, (error) => {
      console.error(error);
      ToastService.success({ message: 'Oops! Your request has not been sent. Please try again later.', autohide: true, keepAfterRouteChange: true });
    });
  }

  const onFormStepChanged = (step, updatedFormValues) => {
    setFormValues({
      ...formValues,
      ...updatedFormValues,
      currentStep: step
    });
  }

  const onFormCanceled = () => {
    setFormValues(DEFAULT_STATE);
    setFormConfigState(syncConfigWithFormValues(formStepConfigs[0]));
  }

  const onFormSubmit = (updatedFormValues) => {
    sendEmail(updatedFormValues, () => setFormValues(DEFAULT_STATE));
  }

  useEffect(() => {
    emailjs.init("HdhmBWfnOXaxOaAP2");
    setFormValues(DEFAULT_STATE);
  }, []);

  useEffect(() => {
    const currentStepConfigIndex = formValues?.currentStep - 1 || 0;
    setFormConfigState(syncConfigWithFormValues(formStepConfigs[currentStepConfigIndex]));
  }, [ formValues?.currentStep ]);
     
  return (
    <BuyingOfferMultiStepFormWrapper { ...props }>
      <BuyingOfferMultiStepFormContent>
        <DynamicForm 
          { ...formProps } 
          config={ formConfigState } 
          steps={ formStepConfigs.length } 
          onCanceled={ onFormCanceled }
          onStepChanged={ onFormStepChanged } 
          onSubmit={ onFormSubmit } />
      </BuyingOfferMultiStepFormContent>
    </BuyingOfferMultiStepFormWrapper>
  );
};

export default BuyingOfferMultiStepForm;
