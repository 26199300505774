import React from 'react';
import { 
    NumeratedListItemWrapper,
    NumeratedListItemNumber
} from './styles';
import { TextSize, TextTypes, HeaderTextTypes } from '../../types';
import HeaderText from '../HeaderText';
import BodyText from '../BodyText';

const NumeratedListItem = (props) => {

  const {
    color = '#949596',
    itemNum,
    title,
    text,
    variant = 'normal'
  } = props;

  return (
    <NumeratedListItemWrapper { ...props }>
      <div className='d-flex flex-row'>
        <NumeratedListItemNumber 
          text={ itemNum } 
          color={ color } 
          type={ variant === 'compact' ? HeaderTextTypes.h6 : HeaderTextTypes.h4 }
        ></NumeratedListItemNumber>
        <div className='flex-grow-1 d-flex flex-column px-3'>
          <HeaderText type={ variant === 'compact' ? HeaderTextTypes.h6 : HeaderTextTypes.h5 } text={ title } />
          <BodyText size={ TextSize.small } type={ TextTypes.span } text={ text } className='pt-2' />
        </div>
      </div>
    </NumeratedListItemWrapper>
  );
};

export default NumeratedListItem;
