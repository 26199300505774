import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { NavigationContextProvider } from './context/NavigationContext';
import { history } from './helpers/history';

import HomePage from './pages';
import AboutUsPage from './pages/about-us';
import Page404 from './pages/404';
import ContactUsPage from './pages/contact-us';
import FaqPage from './pages/faq';
import PrivacyPolicyPage from './pages/privacy-policy';
import CookiePolicyPage from './pages/cookie-policy';
import TermsOfUsePage from './pages/terms-of-use';
import TermsOfServicePage from './pages/terms-of-service';
import CaNoticeAtCollectionPage from './pages/california-notice-at-collection';
import ComponentsPage from './pages/components';
import ToastMessage from './components/Toast';

import './App.scss';

function App() {
  return (
    <NavigationContextProvider>
      <Router history={ history }>
        <ToastMessage />
        <Switch>
          <Route exact path='/' render={(props) => <HomePage {...props} />} />
          <Route exact path='/about-us' render={(props) => <AboutUsPage {...props} />} />
          <Route exact path='/contact-us' render={(props) => <ContactUsPage {...props} />} />
          <Route exact path='/faq' render={(props) => <FaqPage {...props} />} />
          <Route exact path='/privacy-policy' render={(props) => <PrivacyPolicyPage {...props} />} />
          <Route exact path='/cookie-policy' render={(props) => <CookiePolicyPage {...props} />} />
          <Route exact path='/terms-of-use' render={(props) => <TermsOfUsePage {...props} />} />
          <Route exact path='/terms-of-service' render={(props) => <TermsOfServicePage {...props} />} />
          <Route exact path='/california-notice-at-collection' render={(props) => <CaNoticeAtCollectionPage {...props} />} />
          <Route exact path='/components' render={(props) => <ComponentsPage {...props} />} />
          <Route render={(props) => <Page404 {...props} />} />
        </Switch>
      </Router>
    </NavigationContextProvider>
  );
}

export default App;
