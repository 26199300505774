import React, { useState, useEffect } from 'react';
import { cloneDeep } from 'lodash';

import { FormWrapper, Form, FormFooterButtonsWrapper, FormFooterContent, FormSubmitContent } from './styles';
import { COMPANY_FULL_NAME, COMPANY_PHONE_ALT } from '../../shared/globals';
import { ButtonVariant, TextTypes, TextSize, NavLinkStyleTypes, SpacerSize } from '../../types';
import validate from '../../utils/validation';

import { SimpleButton } from '../ButtonElements';
import NavigationLinkWithContext from '../NavigationLinkWithContext';
import HeaderText from '../HeaderText';
import BodyText from '../BodyText';
import Spacer from '../Spacer';

const DynamicForm = (props) => {

  /* Config structure
  {
    title: '',
    subTitle: '',
    titleColor: '',
    footerText: '',
    showSubmitText: true,
    submitLabel: '',
    submitVariant: ButtonVariant.primary,
    maxLength: 7000, // uses for textarea type
    isValid: false,
    controls: {
      [control name]: {
        type: 'input | radio | checkbox | textarea',
        name: '',
        label: '',
        placeholder: '',
        value: '',
        valid: false,
        invalidRule: 'required',
        touched: false,
        validationRules: {
          required: true,
          minLength: 20,
          maxLength: 150
        },
        validationError: {
          required: 'Enter your property address',
          minLength: 'Enter a valid address',
          maxLength: 'Enter a valid address'
        },
        className: 'col-6'
      }
    }
  }
  */

  const {
    formValues = {}, 
    setFormValues = (values) => void 0,
    config,
    steps,
    onCanceled = () => void 0,
    onStepChanged = (stepNum, values) => void 0,
    onSubmit = (values) => void 0
  } = props;

  const [formOrigConfigState, setFormOrigConfigState] = useState(cloneDeep(config));
  const [formConfigState, setFormConfigState] = useState(config);

  const getCurrentFormValues = () => {
    let updatedFormValues = {};
    formConfigState && Object.keys(formConfigState.controls).forEach((controlName) => {
      updatedFormValues[controlName] = formConfigState.controls[controlName].value;
    });
    return !!Object.keys(updatedFormValues).length && updatedFormValues || {};
  }

  const hasMultipleSteps = () => {
    return typeof steps === 'number' && steps > 0;
  }

  const isLastStep = () => {
    if (!hasMultipleSteps()) {
      return true;
    }
    return steps === formValues?.currentStep;
  }

  const isRequired = (conponentName) => {
    let result = false;
    Object.keys(formConfigState?.controls[conponentName]?.validationRules).forEach(ruleName => {
      if (!result && ruleName === 'required') {
        result = true;
      }
    });
    return !!result;
  }

  const resetForm = () => {
    setFormConfigState(formOrigConfigState);
  };

  const onValuesChanged = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const updatedControls = {
      ...formConfigState.controls
    };
    const updatedFormElement = {
      ...updatedControls[name]
    };
    let formIsValid = true;

    updatedFormElement.value = value;
    updatedFormElement.touched = true;

    const validationResult = validate(value, updatedFormElement.validationRules);
    updatedFormElement.valid = typeof validationResult === 'boolean' ? validationResult : Object.values(validationResult)[0];
    updatedFormElement.invalidRule = typeof validationResult === 'boolean' ? null : Object.keys(validationResult)[0];

    updatedControls[name] = updatedFormElement;

    for (let inputIdentifier in updatedControls) {
      formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
    }

    setFormConfigState({
      ...formConfigState,
      controls: updatedControls,
      isValid: formIsValid
    });
  };

  const onCancelClicked = (e) => {
    e.preventDefault();

    if (hasMultipleSteps() && !isLastStep()) {
      // Back button clicked
      typeof onStepChanged === 'function' && onStepChanged(formValues.currentStep - 1, formValues);
    } else {
      // Cancel button clicked
      resetForm();
      typeof onCanceled === 'function' && onCanceled();
    }
  };

  const onSubmitClicked = (e) => {
    e.preventDefault();

    const updatedFormValue = {
      ...formValues,
      ...getCurrentFormValues(),
      currentStep: hasMultipleSteps() ? formValues.currentStep + (!isLastStep() ? 1 : 0) : null
    };

    if (hasMultipleSteps && !isLastStep()) {
      // Next button clicked
      typeof onStepChanged === 'function' && onStepChanged(updatedFormValue.currentStep, updatedFormValue);
    } else {
      // Submit button clicked
      typeof onSubmit === 'function' && onSubmit(updatedFormValue);
    }
  };

  useEffect(() => {
    const origConfig = cloneDeep(config);
    setFormOrigConfigState(origConfig);
    setFormConfigState(config);
  }, [ config ]);

	return (
		<FormWrapper { ...props }>
      <Form>
        <HeaderText text={ formConfigState?.title } type='h5' color={ formConfigState?.titleColor } className={ `mb-2 ${!formConfigState?.title ? 'd-none' : ''}`} />
        <BodyText text={ formConfigState?.subTitle } size='medium' type='label' className={ `${!formConfigState?.subTitle ? 'd-none' : ''}`} />
        <BodyText text={ 'All fields marked with * are required.' } type='p' size={ TextSize.xs } />
        <Spacer size={ SpacerSize.xs } />

        <div className={`row g-3 ${formConfigState?.title || formConfigState?.subTitle ? 'my-2' : ''} mb-4`}>
        {
          formConfigState && Object.keys(formConfigState.controls).map((controlName) => {
            const ctrl = formConfigState.controls[controlName];
            if (ctrl.type === 'input') {
              return (
                <div className={ !!ctrl.className ? ctrl.className : 'col col-12' } key={ ctrl.name }>
                  <BodyText text={ ctrl.label + (isRequired(ctrl.name) ? ' *' : '') } size='medium' type='label' weight={ 600 } for={ ctrl.name } className='form-label' />
                  <input
                    className={ 'form-control ' + (ctrl.touched && !ctrl.valid ? 'is-invalid' : '') }
                    type='text'
                    id={ ctrl.name }
                    name={ ctrl.name }
                    placeholder={ ctrl.placeholder }
                    value={ ctrl.value }
                    touched={ ctrl.touched }
                    valid={ ctrl.valid }
                    onChange={ onValuesChanged }
                  />
                  <div className={ ctrl.touched && !ctrl.valid ? 'invalid-feedback' : 'valid-feedback' }>
                    { ctrl.validationError[ctrl.invalidRule] || 'Invalid data.' }
                  </div>
                </div>
              );
            } else if (ctrl.type === 'checkbox') {
              return null;
            } else if (ctrl.type === 'radio') {
              return (
                <>
                  <div className={ !!ctrl.className ? ctrl.className : 'col col-12' } key={ ctrl.name + '-label' }>
                    <BodyText text={ ctrl.label + (isRequired(ctrl.name) ? ' *' : '') } size='medium' type='label' weight={ 600 } for={ ctrl.name } className='from-label' />
                  </div>
                  {
                    ctrl.options ? ctrl.options.map((option, idx) => 
                      <div className={ `${!!ctrl.className ? ctrl.className : 'col-12'} ${ctrl.options.length === 1 ? 'mt-0' : ''}` } key={ ctrl.name + '-' + idx }>
                        <input
                          className={ 'form-radio-group-control ' + (ctrl.touched && !ctrl.valid ? 'is-invalid' : '') }
                          type='radio'
                          id={ ctrl.name + '-option-' +idx }
                          name={ ctrl.name }
                          placeholder={ ctrl.placeholder }
                          value={ option.value }
                          touched={ ctrl.touched }
                          valid={ ctrl.valid }
                          checked={ ctrl.value === option.value ? 'checked' : '' }
                          onChange={ onValuesChanged }
                        />
                        <BodyText text={ option.label } size='medium' type='label' for={ ctrl.name + '-option-' +idx } className='px-2' />
                      </div>
                    ) : null
                  }
                  <div className={ ctrl.touched && !ctrl.valid ? 'invalid-feedback' : 'valid-feedback' }>
                    { ctrl.validationError[ctrl.invalidRule] || 'Invalid data.' }
                  </div>
                </>
              );
            } else if (ctrl.type === 'textarea') {
              return (
                <div className={ !!ctrl.className ? ctrl.className : 'col col-12' } key={ ctrl.name }>
                  <BodyText text={ ctrl.label + (isRequired(ctrl.name) ? ' *' : '') } size='medium' type='label' weight={ 600 } for={ ctrl.name } className='form-label' />
                  <textarea
                    className={ 'form-control ' + (ctrl.touched && !ctrl.valid ? 'is-invalid' : '') }
                    id={ ctrl.name }
                    name={ ctrl.name }
                    placeholder={ ctrl.placeholder }
                    value={ ctrl.value }
                    touched={ ctrl.touched }
                    valid={ ctrl.valid }
                    maxlength={ ctrl.maxLength || 5000 }
                    onChange={ onValuesChanged }
                  />
                  <div className={ ctrl.touched && !ctrl.valid ? 'invalid-feedback' : 'valid-feedback' }>
                    { ctrl.validationError[ctrl.invalidRule] || 'Invalid data.' }
                  </div>
                </div>
              );
            } else {
              return null;
            }
          }) || null
        }
        </div>

        <FormFooterContent 
          className={ `my-4 ${ !!formConfigState?.footerText?.length ? '' : 'd-none' }` }
        >
          <BodyText 
            type={ TextTypes.div } 
            size={ TextSize.small }
            alignment='justify'
          >
            { formConfigState?.footerText }
          </BodyText>
        </FormFooterContent>

        <FormFooterButtonsWrapper>
          <SimpleButton 
            className={ `inverted ${ hasMultipleSteps() && formValues?.currentStep === 1 ? 'd-none' : '' }` }
            variant={ ButtonVariant.secondary }
            dark={ false }
            onClick={ onCancelClicked }
          >
            { hasMultipleSteps() && !isLastStep() ? 'Back' : 'Cancel' }
          </SimpleButton>

          <SimpleButton 
            className={ (formConfigState && !formConfigState.isValid ? 'disabled' : '') }
            variant={ formConfigState?.submitVariant ? formConfigState?.submitVariant : ButtonVariant.primary }
            dark={ false }
            disabled={ formConfigState && !formConfigState.isValid }
            onClick={ onSubmitClicked }
          >
            { hasMultipleSteps() && !isLastStep() ? 'Next' : !!formConfigState?.submitLabel?.length ? formConfigState?.submitLabel : 'Submit' }
          </SimpleButton>
        </FormFooterButtonsWrapper>

        <FormSubmitContent 
          className={ `mt-4 ${ !!formConfigState?.showSubmitText && formConfigState?.isValid ? '' : 'd-none' }` }
        >
          <BodyText 
            type={ TextTypes.div } 
            size={ TextSize.small }
            alignment='justify'
          >
            <span>By submitting your phone number you agree to receive text messages from {COMPANY_PHONE_ALT}, if you do not wish to receive text messages, reply STOP.</span><br/><br/>
            <span>By clicking the button, I agree to the <NavigationLinkWithContext isExternalLink={ true }  title={ 'Terms of Use' } path={ '/terms-of-use' } style={ NavLinkStyleTypes.normal } alt={ 'Terms of Use link' } /> and consent to receive calls, texts, voicemails (including marketing by autodialer and prerecorded and artificial voice) and emails at the phone number and email address I provided, including automated service alerts and marketing messages from and on behalf of {COMPANY_FULL_NAME} and their network of service providers. I understand that this consent applies even if I am on a do not call list, that consent is not required for purchase, and that I can call {COMPANY_PHONE_ALT} to proceed without consent. Msg/data rates may apply. If you do not wish to receive text messages, reply STOP.</span>
          </BodyText>
        </FormSubmitContent>
      </Form>
		</FormWrapper>
	)
}

export default DynamicForm;