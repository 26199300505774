import React from 'react';

import { TextSize, TextTypes } from '../../types';
import {
  PageContentContainer,
  ContentContainer,
  ColoredShape,
  PageHeaderContainer,
  ContactTypesContainer,
  ContactFormContainer
} from './styles';
import { COMPANY_BUSINESS_HOURS_1, COMPANY_BUSINESS_HOURS_2, COMPANY_BUSINESS_HOURS_TZ } from '../../shared/globals';
import SectionTitle from '../SectionTitle';
import ContactTypes from '../ContactTypes';
import ContactForm from '../ContactForm';
import BodyText from '../BodyText';

const ContactUs = (props) => {

  return (
    <PageContentContainer>
      <ContentContainer>
        <ColoredShape />
        <PageHeaderContainer>
          <SectionTitle headerText={ 'Contact Us' } textSize={ TextSize.middle } rightPadding={ '40px' }>
            <BodyText type={ TextTypes.div } size={ TextSize.middle }>
              <p>You are always welcome to contact us. Our Customer Care Center is available <b>{ COMPANY_BUSINESS_HOURS_1 }</b> and <b>{ COMPANY_BUSINESS_HOURS_2 }</b> (<b>{ COMPANY_BUSINESS_HOURS_TZ }</b>). We look forward to hearing from you!</p>
            </BodyText>
          </SectionTitle>
        </PageHeaderContainer>
        <ContactTypesContainer>
          <ContactTypes></ContactTypes>
        </ContactTypesContainer>
        <ContactFormContainer>
          <ContactForm></ContactForm>
        </ContactFormContainer>
      </ContentContainer>
    </PageContentContainer>
  );
};

export default ContactUs;
