import styled from 'styled-components';
import { respondTo } from '../mixins/responsive';

export const ContainerGridWrapper = styled.div`
  margin: 0 auto;
  padding: 0;
  display: grid;
  grid-template-columns: 12fr;
  grid-template-rows: 1fr;
  position: relative;
  width: 100%;
  height: ${({ takeFullViewport }) => takeFullViewport ? '100vh' : 'auto'};
  overflow: hidden;

  ${respondTo.xxs.standard`
    grid-template-columns: 4fr;
    max-width: 100%;
  `};

  ${respondTo.xs.standard`
    grid-template-columns: 6fr;
    max-width: ${({ isFullWidth }) => isFullWidth ? '100%' : '352px'};
  `};

  ${respondTo.sm.standard`
    grid-template-columns: 6fr;
    max-width: ${({ isFullWidth }) => isFullWidth ? '100%' : '516px'};
  `};

  ${respondTo.md.standard`
    grid-template-columns: 12fr;
    max-width: ${({ isFullWidth }) => isFullWidth ? '100%' : '696px'};
  `};

  ${respondTo.lg.standard`
    grid-template-columns: 12fr;
    max-width: ${({ isFullWidth }) => isFullWidth ? '100%' : '936px'};
  `};

  ${respondTo.xl.standard`
    grid-template-columns: 12fr;
    max-width: ${({ isFullWidth }) => isFullWidth ? '100%' : '1118px'};
  `};

  ${respondTo.xxl.standard`
    grid-template-columns: 12fr;
    max-width: ${({ isFullWidth }) => isFullWidth ? '100%' : '1296px'};
  `};
`;