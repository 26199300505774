import React from 'react';
import GeneralLayout from '../layouts/GeneralLayout';
import { 
  LayoutTypes, 
  TextSize, 
  ContentFloat, 
  SpacerSize, 
  ViewHeightVariant, 
  ContentVerticalFloat, 
  NavLinkStyleTypes, 
  ButtonVariant, 
  InfoBlockBgLinePosition,
  TextTypes,
  HeaderTextTypes
} from '../types';
import { COMPANY_NAME, COMPANY_PHONE_ALT, COMPANY_SLOGAN, SERVICE_STATES } from '../shared/globals';
import { getMainMenuItems } from '../utils/nav-menu';
import { PageContentBlock, PageContentAlignment } from './styles';

import Spacer from '../components/Spacer';
import ContainerGrid from '../components/ContainerGrid';
import ContainerWithBackground from '../components/ContainerWithBackground';
import BoderShapeBlock from '../components/BoderShapeBlock';
import BgShapeBlock from '../components/BgShapeBlock';
import Image from '../components/Image';
import SectionTitle from '../components/SectionTitle';
import BodyText from '../components/BodyText';
import { FormContextProvider } from '../context/FormContext';
import WithFormContext from '../components/WithFormContext';
import BuyingOfferMultiStepForm from '../components/BuyingOfferMultiStepForm';
import NavigationLinkWithContext from '../components/NavigationLinkWithContext';

import Accordion from 'react-bootstrap/Accordion';
import HeaderText from '../components/HeaderText';

const FaqPage = () => {
  
  const baseUrl = process.env.REACT_APP_BUYING_HOUSE_DOMAIN_BASE_URL;
  const baseParentUrl = process.env.REACT_APP_SOPHINEX_DOMAIN_BASE_URL;
  const navOptions = {
    ...getMainMenuItems(LayoutTypes.main),
    baseUrl,
    baseParentUrl,
    isBgDark: false,
    isSideBgDark: false
  };

  return (
    <GeneralLayout {...navOptions}>

      <Spacer />

      <ContainerGrid>
        <PageContentBlock>
          <SectionTitle
            topText={ 'Selling Houses' }
            headerText={ 'Frequently Asked Questions (FAQs)' }
            textSize={ TextSize.large }
            headerFloat={ ContentFloat.left }
          >
            <BodyText text={ `This is a list of questions and answers intended to help you understand what we do and which services we provide.` } type={ TextTypes.sapn } size={ TextSize.middle }></BodyText>
          </SectionTitle>
        </PageContentBlock>

        <Spacer size={ SpacerSize.xs } />

        <div className='row mx-1'>
          <div className='col-xs-12 col-md-6 col-lg-6'>
            <Accordion defaultActiveKey={[]} alwaysOpen flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <HeaderText text={ 'What type of houses do you purchase?' } type={ HeaderTextTypes.h6 } />
                </Accordion.Header>
                <Accordion.Body>
                  <BodyText text={ `${ COMPANY_NAME } is a flexible investor but we purchase only single family houses in many states.` } type={ TextTypes.p } size={ TextSize.middle }></BodyText>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <HeaderText text={ 'Does a home need to be in a specific condition?' } type={ HeaderTextTypes.h6 } />
                </Accordion.Header>
                <Accordion.Body>
                  <BodyText text={ 'We buy houses in most states and in any condition. Having been in the business for years, we have had experience purchasing properties and providing solutions for homes of all qualities and conditions.' } type={ TextTypes.p } size={ TextSize.middle }></BodyText>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <HeaderText text={ 'Do you collaborate with realtors?' } type={ HeaderTextTypes.h6 } />
                </Accordion.Header>
                <Accordion.Body>
                  <BodyText type={ TextTypes.div } size={ TextSize.middle }>
                    No, we do not use real estate agents to make a process of selling properties faster and commission free.
                  </BodyText>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <HeaderText text={ 'How long does it take before I get an offer?' } type={ HeaderTextTypes.h6 } />
                </Accordion.Header>
                <Accordion.Body>
                  <BodyText text={ `While some cash property buyers take an average of two days before making an offer, ${ COMPANY_NAME } can make you an offer in less than 24 hours.` } type={ TextTypes.p } size={ TextSize.middle }></BodyText>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <HeaderText text={ 'Is it necessary to immediately accept your offer?' } type={ HeaderTextTypes.h6 } />
                </Accordion.Header>
                <Accordion.Body>
                  <BodyText type={ TextTypes.div } size={ TextSize.middle }>
                    <p>When you receive our offer, you can take your time to think about it before making a decision. We do not need any immediate commitment from you, and we will be more than happy to accommodate a situation that works best for you.</p>
                    <p>When you have a decision in mind, just give us a call or send us an e-mail and we will talk more about it.</p>
                  </BodyText>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  <HeaderText text={ `Do I have to sell to ${ COMPANY_NAME} if I contact you for a free offer?` } type={ HeaderTextTypes.h6 } />
                </Accordion.Header>
                <Accordion.Body>
                  <BodyText type={ TextTypes.div } size={ TextSize.middle }>
                    <p>All of our offers are made at no cost to you and you are never obligated to sell your house to { COMPANY_NAME }. You can choose to accept our free offer, or you can choose not to. It is all up to you.</p>
                  </BodyText>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  <HeaderText text={ 'Can I expect a market value offer for my house?' } type={ HeaderTextTypes.h6 } />
                </Accordion.Header>
                <Accordion.Body>
                  <BodyText text={ 'We give you a fair offer based on the market, the condition of the house, and other factors. We take the house “as-is”, no repairs or cleaning is needed. And, you will not have to hire a real estate agent or pay listing and closing fees, either. It is a simple and worry-free deal.' } type={ TextTypes.p } size={ TextSize.middle }></BodyText>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className='col-xs-12 col-md-6 col-lg-6'>
            <Accordion defaultActiveKey={[]} alwaysOpen flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <HeaderText text={ 'What is your process of calculating the offer?' } type={ HeaderTextTypes.h6 } />
                </Accordion.Header>
                <Accordion.Body>
                  <BodyText type={ TextTypes.div } size={ TextSize.middle }>
                    <p>When we calculate an offer, we look at:</p>
                    <ul>
                      <li>the existing condition of your house;</li>
                      <li>the extent and cost of repairs or renovation needed to resell your house;</li>
                      <li>the time it will take to finish needed repairs;</li>
                      <li>the value of your house compared to other comparable houses in the area;</li>
                      <li>the existing amount of arrears;</li>
                      <li>the real estate commission and closing fees required to resell your house;</li>
                      <li>the costs associated with maintaining your home during reselling, and more.</li>
                    </ul>
                  </BodyText>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <HeaderText text={ 'Does it take long before you can close escrow?' } type={ HeaderTextTypes.h6 } />
                </Accordion.Header>
                <Accordion.Body>
                  <BodyText text={ 'Depending on the situation, we can close escrow as fast as seven days, but if you or your tenant needs more time to vacate the house or deal with a problem, we can be flexible. Talk to us and we will come up with a timeline that suits everyone\’s schedules.' } type={ TextTypes.p } size={ TextSize.middle }></BodyText>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <HeaderText text={ 'Is it possible to avoid foreclosure?' } type={ HeaderTextTypes.h6 } />
                </Accordion.Header>
                <Accordion.Body>
                  <BodyText text={ 'Having a foreclosure on your record is not a good sign, so we make sure to buy your home before an auction to avoid this outcome.' } type={ TextTypes.p } size={ TextSize.middle }></BodyText>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <HeaderText text={ 'What is the procedure involved in selling houses?' } type={ HeaderTextTypes.h6 } />
                </Accordion.Header>
                <Accordion.Body>
                  <BodyText type={ TextTypes.div } size={ TextSize.middle }>
                    <p>To sell with { COMPANY_NAME }, all you have to do is fill out our <b><NavigationLinkWithContext title={ 'online form' } path={ '/#get-offer' } style={ NavLinkStyleTypes.normal } alt={ 'Go to testimonials' } /></b> or give us a call at <b>{ COMPANY_PHONE_ALT }</b>. We will talk briefly about the details of your property, then offer a quote. Once you are happy with our price, we do all paperwork and setup a date for closing. Then we meet at the title company's office or use on-line technologies to sign documents for closing the deal and transferring funds.</p>
                  </BodyText>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <HeaderText text={ 'What other issues have you helped owners solve before?' } type={ HeaderTextTypes.h6 } />
                </Accordion.Header>
                <Accordion.Body>
                  <BodyText type={ TextTypes.div } size={ TextSize.middle }>
                    <p>We buy houses in many states to help owners with their issues, whether they are going through a split, filing bankruptcy, facing foreclosure, and so much more — we have seen it all and we want to help you out. If you have a property you want to sell, give us a call at <b>{ COMPANY_PHONE_ALT }</b> or <b><NavigationLinkWithContext title={ 'request an offer' } path={ '/#get-offer' } style={ NavLinkStyleTypes.normal } alt={ 'Go to testimonials' } /></b> and we will take care of the rest of the process and expecies associated with it.</p>
                  </BodyText>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  <HeaderText text={ 'What areas do you service?' } type={ HeaderTextTypes.h6 } />
                </Accordion.Header>
                <Accordion.Body>
                  <BodyText text={ `We do service in the following states: ${ SERVICE_STATES}.` } type={ TextTypes.p } size={ TextSize.middle }></BodyText>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  <HeaderText text={ `What sets ${ COMPANY_NAME } apart from its competitors?` } type={ HeaderTextTypes.h6 } />
                </Accordion.Header>
                <Accordion.Body>
                  <BodyText type={ TextTypes.div } size={ TextSize.middle }>
                    <p>As an incredibly lucrative industry, the real estate market is highly competitive, so it is only natural to ask this question.</p>
                    <p>What sets us apart from the rest is our commitment to our word, our effective communication skills, and our constant effort to add value to everyone we work with.</p>
                    <p>{ COMPANY_SLOGAN }</p>
                  </BodyText>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>

        <Spacer size={ SpacerSize.xs } />

      </ContainerGrid>

      <Spacer size={ SpacerSize.xs } />

      <ContainerGrid>
        <PageContentBlock>
          <BgShapeBlock shapePos={ InfoBlockBgLinePosition.half } float={ ContentFloat.right } baseZIndex={ 0 }>
            <SectionTitle
              topText={ '' }
              headerText={ 'Disclaimer' }
              textSize={ TextSize.middle }
              headerFloat={ ContentFloat.right }
            >
              <BodyText text={ 'This website is not intended to provide nor does it provide legal advice.' } type={ TextTypes.p } alignment='right' />
            </SectionTitle>
          </BgShapeBlock>
        </PageContentBlock>
      </ContainerGrid>

      <Spacer />

      <ContainerGrid>
        <PageContentBlock>
          <BgShapeBlock shapePos={ InfoBlockBgLinePosition.half } float={ ContentFloat.left } baseZIndex={ 0 }>
            <SectionTitle
              topText={ '' }
              headerText={ 'Still Have Questions?' }
              textSize={ TextSize.middle }
              headerFloat={ ContentFloat.left }
            >
              <BodyText type='div' alignment={ ContentFloat.left }>
                <span>Please call us at { COMPANY_PHONE_ALT } or use our "Contact Us" form. We look forward to hearing from you.</span>
              </BodyText>
              <Spacer size='sm'></Spacer>
              <PageContentAlignment align={ ContentFloat.left } noGap={ true }>
                <NavigationLinkWithContext 
                  title={ 'Contact Us' } 
                  path={ '/contact-us' } 
                  isExternalLink={ false } 
                  style={ NavLinkStyleTypes.button }
                  variant={ ButtonVariant.primary }
                  dark={ false } 
                  alt={ 'Contact Us?' } />
              </PageContentAlignment>
            </SectionTitle>
          </BgShapeBlock>
        </PageContentBlock>
      </ContainerGrid>

      <Spacer />

      <ContainerGrid className='d-none'>
        <PageContentBlock>
          <BoderShapeBlock color={ '#88bc95' } opacity={ 0.15 }>
            <BgShapeBlock shapePos={ InfoBlockBgLinePosition.none } float={ ContentFloat.center } fullHeight={ false } baseZIndex={ 0 }>
              <div className='row g-4'>
                <div className='col-xs-12 col-md-12 col-lg-6'>
                    <PageContentAlignment align={ ContentFloat.center } vAlign={ ContentVerticalFloat.center } fullHeight={ false } noGap={ true }>
                      <Image 
                        img={ 'mail-sent-pana-work.svg' } 
                        style={{zIndex: '3'}} 
                        imgSourceColor={ 'on-light' }
                        imgSourceHref={ 'https://storyset.com/work' }
                        imgSourceTitle={ 'Work illustrations by Storyset' }
                        imgSourceIntro={ 'illustrations by storyset' }
                        imgSourceAlignment={ 'bottom-center' }
                      />
                    </PageContentAlignment>
                </div>
                <div className='col-xs-12 col-md-12 col-lg-6'>
                  <PageContentAlignment align={ ContentFloat.left } vAlign={ ContentVerticalFloat.top } fullHeight={ true } noGap={ true }>
                    <SectionTitle
                      headerText={ 'Request your free offer today' }
                      textSize={ TextSize.large }
                      headerFloat={ ContentFloat.left }
                    >
                      <BodyText type='div' alignment={ 'justify' }>
                        <p>Tell us about your property to receive a free no-obligation offer in less than 24 hours. Please complete all required fields (marked with *) to help us make the right offer for you.</p>
                        <p>We do not share your personal information.</p>
                      </BodyText>
                      <Spacer size='sm'></Spacer>
                      <ContainerWithBackground 
                        bgColor={ '#e2e2e2' } 
                        bgOverlayColor={ '#ffffff'} 
                        bgOverlayTransparency={ 0.3 }
                        heightVariant={ ViewHeightVariant.auto }
                        className='p-4 card-shadow'
                        style={{ borderRadius: '16px' }}
                      >
                        <FormContextProvider>
                          <WithFormContext render={(propsWithContext) => <BuyingOfferMultiStepForm { ...propsWithContext }></BuyingOfferMultiStepForm>} />
                        </FormContextProvider>
                      </ContainerWithBackground>
                    </SectionTitle>
                  </PageContentAlignment>
                </div>
              </div>
              </BgShapeBlock>
          </BoderShapeBlock>
        </PageContentBlock>
      </ContainerGrid>
    </GeneralLayout>
  );
}

export default FaqPage;
