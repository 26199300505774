import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { LayoutTypes } from '../../types'
import { NavigationContext } from '../../context/NavigationContext';
import WithNavigationContext from '../../components/WithNavContext';

import ScrollToTop from '../../components/ScrollToTop';
import DynamicSideNav from '../../components/DynamicSideNav';
import DynamicNavbar from '../../components/DynamicNavbar';
import { MainContent } from './styles';
import Footer from '../../components/Footer';

const GeneralLayout = (props) => {

  const { isOpen, setIsOpen, layoutType, setLayoutType } = useContext(NavigationContext);

  let location = useLocation();

  useEffect(() => {
    // Change layout type to a target one
    if (layoutType !== props.layoutType) {
      setLayoutType(props.layoutType);
    }
    // Handle anchor navigation
    const anchor = location.hash.replace('#', '');
      if (anchor) {
        const domElement = document.querySelector(`[id="${anchor}"]`);
        if (domElement) {
          domElement.scrollIntoView();
        }
      }
  }, [location, layoutType, setLayoutType]);

  const toggle = () => setIsOpen(!isOpen);

  return(
    <>
      <ScrollToTop />

      <WithNavigationContext onClick={toggle} render={ (propsWithContext) => <DynamicSideNav { ...props } isBgDark={ props?.isSideBgDark } { ...propsWithContext } /> }/>
      <WithNavigationContext onClick={toggle} render={ (propsWithContext) => <DynamicNavbar { ...props } { ...propsWithContext } /> }/>

      <MainContent isNavMenuVisible={ layoutType === LayoutTypes.main }>
        { props.children }
      </MainContent>

      <WithNavigationContext render={ (propsWithContext) => <Footer { ...propsWithContext } baseUrl={props?.baseUrl}  baseParentUrl={props?.baseParentUrl} isExternalLink={ props?.layoutType === LayoutTypes.subdom } /> }/>
    </>
  )
}

GeneralLayout.propTypes = {
  baseUrl: PropTypes.string,
  layoutType: PropTypes.string,
  menuItems: PropTypes.array,
  menuButton: PropTypes.object,
  onClick: PropTypes.func
};

GeneralLayout.defaultProps = {
  baseUrl: '/', 
  layoutType: LayoutTypes.main, 
  menuItems: [],
  menuButton: null,
  onClick: (event) => {}
};

export default GeneralLayout;