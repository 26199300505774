import React from 'react';
import GeneralLayout from '../layouts/GeneralLayout';
import { LayoutTypes, TextSize, ButtonVariant, NavLinkStyleTypes, ContentFloat, ContentVerticalFloat, InfoBlockBgLinePosition, ViewHeightVariant } from '../types';
import { getMainMenuItems } from '../utils/nav-menu';
import { COMPANY_DESCRIPTION, COMPANY_NAME, COMPANY_SLOGAN } from '../shared/globals';

import { heroSlideAboutUs } from '../data/page-about-content';
import { PageContentBlock, PageContentAlignment } from './styles';
import NavigationLinkWithContext from '../components/NavigationLinkWithContext';
import BlockSection from '../components/BlockSection';
import ContainerWithBackground from '../components/ContainerWithBackground';
import ContainerGrid from '../components/ContainerGrid';
import Spacer from '../components/Spacer';
import BodyText from '../components/BodyText';
import SectionTitle from '../components/SectionTitle';
import NumeratedListItem from '../components/NumereatedListItem';
import BgShapeBlock from '../components/BgShapeBlock';
import Image from '../components/Image';

const AboutUsPage = () => {

  const baseUrl = process.env.REACT_APP_NO_FORECLOSUE_DOMAIN_BASE_URL;
  const baseParentUrl = process.env.REACT_APP_SOPHINEX_DOMAIN_BASE_URL;
  const navOptions = {
    ...getMainMenuItems(LayoutTypes.main),
    baseUrl,
    baseParentUrl,
    isBgDark: false,
    isSideBgDark: false
  };

  return (
    <GeneralLayout {...navOptions}>

      <ContainerWithBackground 
        bgImage={ '31179995_10.jpg' }
        bgColor={ '#e2e2e2' } 
        bgOverlayColor={ '#ffffff'} 
        bgOverlayTransparency={ 0.6 }
        heightVariant={ ViewHeightVariant.custom }
        height={ 'calc(100vh - 80px)' }
      >
        <BlockSection { ...heroSlideAboutUs } textSize={ TextSize.large } sectionHeight={ '100%' } highlightContent={ true } className='h-100' />
      </ContainerWithBackground>

      <Spacer id='we-are-different' />

      <ContainerGrid>
        <PageContentBlock>
            <div className='row g-3'>
                <div className='col-xs-12 col-md-12 col-lg-6 p-0-'>
                    <BgShapeBlock shapePos={ InfoBlockBgLinePosition.side } float={ ContentFloat.left } fullHeight={ true } baseZIndex={ 0 }>
                        <SectionTitle
                            headerText={ 'It Is Different To Work With Us' }
                            textSize={ TextSize.large }
                            weight={ 700 }
                            headerFloat={ ContentFloat.left }
                        >
                            <BodyText type='div' alignment={ 'justify' }>
                                <span>{ COMPANY_SLOGAN }</span>
                            </BodyText>
                            <Spacer size='sm'></Spacer>
                            <PageContentAlignment align={ ContentFloat.right } noGap={ true }>
                                <NavigationLinkWithContext 
                                    title={ 'Learn More About Us' } 
                                    path={ '/about-us' } 
                                    hash={ 'about' } 
                                    isExternalLink={ false } 
                                    style={ NavLinkStyleTypes.button }
                                    className='inverted-' 
                                    variant={ ButtonVariant.primary }
                                    dark={ false } 
                                    alt={ 'Learn More About Us' } />
                            </PageContentAlignment>
                        </SectionTitle>
                    </BgShapeBlock>
                </div>
                <div className='col-xs-12 col-md-12 col-lg-6 p-5'>
                  <NumeratedListItem 
                    itemNum={ '01' } 
                    color={ '#74b587' } 
                    title={ 'Individual approach to each case' } 
                    text={ 'We use individual approach to each case based on current sutuation.' }
                    variant={ 'compact' } 
                    className={ 'pb-4' } />
                  <NumeratedListItem 
                    itemNum={ '02' } 
                    color={ '#74b587' } 
                    title={ 'Creative financing' } 
                    text={ 'We use creative financing for purchasing and selling properties.' }
                    variant={ 'compact' }
                    className={ 'pb-4' } />
                  <NumeratedListItem 
                    itemNum={ '03' } 
                    color={ '#74b587' } 
                    title={ 'Working according your schedule' } 
                    text={ 'We respect your time and working accourding your schedule.' }
                    variant={ 'compact' }
                    className={ 'pb-4' } />
                  <NumeratedListItem 
                    itemNum={ '04' } 
                    color={ '#74b587' } 
                    title={ 'Fast offer and closing' } 
                    text={ 'Having all information about your property, we would make your an offer in less than 24 hours. We partner with local title companies to be able to complete closing in less than a week (depends on case).' }
                    variant={ 'compact' }
                    className={ 'pb-4' } />
                </div>
            </div>
        </PageContentBlock>
      </ContainerGrid>

      <Spacer id='about' />
      
      <ContainerGrid>
        <PageContentBlock>
          <div className='row g-4'>
            <div className='col-xs-12 col-md-12 col-lg-6 px-0 pt-5 pb-2'>
              <PageContentAlignment align={ ContentFloat.center } vAlign={ ContentVerticalFloat.top } noGap={ true }>
                <ContainerWithBackground 
                  bgColor={ '#0b930b' } 
                  bgOverlayColor={ '#0b930b'} 
                  bgOverlayTransparency={ 1 }
                  heightVariant={ ViewHeightVariant.custom }
                  className='ps-5 pe-4'
                >
                  <ContainerWithBackground 
                    bgColor={ '#ffffff' } 
                    bgOverlayColor={ '#ffffff'} 
                    bgOverlayTransparency={ 1 }
                    heightVariant={ ViewHeightVariant.custom }
                    baseZIndex={ 3 }
                  >
                    <PageContentAlignment align={ ContentFloat.center } vAlign={ ContentVerticalFloat.center } fullHeight={ true } noGap={ true }>
                      <Image 
                        img={ 'team-pana.svg' } 
                        style={{zIndex: '6'}} 
                        imgSourceColor={ 'on-light' }
                        imgSourceHref={ 'https://storyset.com/work' }
                        imgSourceTitle={ 'Work illustrations by Storyset' }
                        imgSourceIntro={ 'illustrations by storyset' }
                        imgSourceAlignment={ 'bottom-center' }
                        imgSourceVerticalOffset={ -35 }
                      />
                    </PageContentAlignment>
                  </ContainerWithBackground>
                </ContainerWithBackground>

                <BgShapeBlock shapePos={ InfoBlockBgLinePosition.center } float={ ContentFloat.center } color={ '#0b930b' } fullHeight={ true } baseZIndex={ 0 } noGap={ true } className='px-4 d-none' style={{ height: '200px'}}>
                  <BgShapeBlock shapePos={ InfoBlockBgLinePosition.center } float={ ContentFloat.left } color={ '#ffffff' } fullHeight={ true } baseZIndex={ 0 } noGap={ true } className='mx-2-'>
                    <PageContentAlignment align={ ContentFloat.center } vAlign={ ContentVerticalFloat.center } fullHeight={ true } noGap={ true }>
                      <Image 
                        img={ 'team-pana.svg' } 
                        style={{zIndex: '3'}} 
                        imgSourceColor={ 'on-light' }
                        imgSourceHref={ 'https://storyset.com/work' }
                        imgSourceTitle={ 'Work illustrations by Storyset' }
                        imgSourceIntro={ 'illustrations by storyset' }
                        imgSourceAlignment={ 'bottom-center' }
                      />
                    </PageContentAlignment>
                  </BgShapeBlock>
                </BgShapeBlock>
              </PageContentAlignment>
            </div>

            <div className='col-xs-12 col-md-12 col-lg-6 p-0'>
              <ContainerWithBackground 
                bgColor={ '#e2e2e2' } 
                bgOverlayColor={ '#000000'} 
                bgOverlayTransparency={ 0.3 }
                heightVariant={ ViewHeightVariant.full }
              >
                <PageContentAlignment align={ ContentFloat.center } vAlign={ ContentVerticalFloat.center } fullHeight={ true } noGap={ true } className='p-5'>
                  <SectionTitle
                    headerText={ 'About Us' }
                    textSize={ TextSize.large }
                    weight={ 700 }
                    headerFloat={ ContentFloat.left }
                  >
                    <BodyText type='div' alignment={ 'justify' }>
                      <p>{ COMPANY_DESCRIPTION }</p>
                      <p>Need to sell your house quickly? Get an instant cash offer with { COMPANY_NAME }!</p>
                      <p>Sell your house as-is without any fees at your convenience. We work according to your schedule. Send us your address and we will provide you a quick offer for your property.</p>
                      <p>If you want to sell your house fast and sell it as is, { COMPANY_NAME }, the cash home buyer is your answer!</p>
                    </BodyText>
                    <Spacer size='sm'></Spacer>
                    <PageContentAlignment align={ ContentFloat.right } noGap={ true }>
                      <NavigationLinkWithContext 
                        title={ 'Get Free Offer' } 
                        path={ '/' } 
                        hash={ 'get-offer' } 
                        isExternalLink={ false } 
                        style={ NavLinkStyleTypes.button }
                        variant={ ButtonVariant.success }
                        dark={ false } 
                        alt={ 'Get Free Offer' } />
                    </PageContentAlignment>
                  </SectionTitle>
                </PageContentAlignment>
              </ContainerWithBackground>
            </div>
          </div>
        </PageContentBlock>
      </ContainerGrid>

      <Spacer />

      <ContainerGrid isFullWidth={ true } className='d-none-'>
        <ContainerWithBackground
          bgImage={ '31179995_10.jpg' }
          bgColor={ '#e2e2e2' } 
          bgOverlayColor={ '#000000'} 
          bgOverlayTransparency={ 0.3 }
          takeFullViewport ={ false }
        >
          <ContainerGrid>
            <div className='row'>
              <div className='col-xs-12 col-md-6 col-lg-8'>
                <PageContentBlock className='py-5 px-1'>
                  <SectionTitle
                    headerText={ `What Do Home Owners Say?` }
                    textSize={ TextSize.middle }
                    headerFloat={ ContentFloat.right }
                  />
                </PageContentBlock>
              </div>
              <div className='col-xs-12 col-md-6 col-lg-4 h-100'>
                <PageContentAlignment align={ ContentFloat.left } vAlign={ 'center' } noGap={ true } className='h-100'>
                  <NavigationLinkWithContext 
                    title={ 'Go To Testimonials' } 
                    path={ '/' } 
                    hash={ 'testimonials' }
                    isExternalLink={ false } 
                    style={ NavLinkStyleTypes.button }
                    className='inverted' 
                    variant={ ButtonVariant.primary }
                    dark={ false } 
                    alt={ 'Go To Testimonials' } />
                </PageContentAlignment>
                <Spacer size='md'></Spacer>
              </div>
            </div>
          </ContainerGrid>
        </ContainerWithBackground>
      </ContainerGrid>

    </GeneralLayout>
  );
}

export default AboutUsPage;
