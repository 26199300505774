import React from 'react';

import Section from '../Section';
import InfoBlock from '../InfoBlock';
import { TopShape, Body } from './styles';
import ContactForm from '../ContactForm';

const WhatWeCanDoComponent = ({ children }) => {
  
  const ContactFormProps = {
    hideTitle: true,
    templateId: 'surplus_template',
    messageMaxLength: 7000
  };
 
  return (
    <Section id='section-1' transparent={true}>
        <TopShape />
        <Body>
          { children }
        </Body>
    </Section>
  );
};

export default WhatWeCanDoComponent;
