import { ContentFloat, InfoBlockBgLinePosition, TextSize } from '../types';
import { COMPANY_PHONE_ALT } from '../shared/globals';

export const heroSlideData = {
  id: 'slide_buying_homes',
  lightBg: false,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Duis finibus',
  headline: 'Lorem ipsum dolor sit amet consectetur adipiscing elit.',
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus tempus nisl ut suscipit tempus. Vestibulum dolor nunc, ultrices ac pretium euismod, efficitur at felis. Ut placerat pulvinar dui. Fusce quis lacinia est. Duis venenatis urna a ultrices efficitur.',
  buttonLabel: 'Learn more',
  imgStart: false,
  img: require('../images/for-sale-pana-building.svg'),
  imgOverlay: true,
  imgOverlayTransparency: 0.3,
  imgSourceHref: 'https://storyset.com/business',
  imgSourceTitle: 'Business illustrations by Storyset',
  imgSourceIntro: 'illustration by storyset',
  imgSourceColor: 'on-light',
  imgSourceLocation: 'relative',
  imgSourceAlignment: 'bottom-center',
  imgSourceVerticalOffset: 0,
  imgSourceShowIcon: false,
  bgColor: '',
  alt: 'Learn more',
  dark: true,
  primary: true,
  darkText: true,
  transparent: true,
  inverted: false,
  to: 'test'
};

export const infoBlockData = {
  id: 'info_block_data',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  contentFloat: ContentFloat.left,
  headerFloat: ContentFloat.left,
  shapePos: InfoBlockBgLinePosition.half,
  textSize: TextSize.middle,
  topLine: '',
  headline: 'Mauris eu dignissim vehicula',
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus tempus nisl ut suscipit tempus. Vestibulum dolor nunc, ultrices ac pretium euismod, efficitur at felis. Ut placerat pulvinar dui. Fusce quis lacinia est. Duis venenatis urna a ultrices efficitur. Vestibulum luctus quis elit in luctus. Suspendisse efficitur nec odio eget rutrum. Sed rutrum nibh volutpat volutpat malesuada. Donec eu semper sapien. Suspendisse eu suscipit mauris.',
  dark: false,
  primary: true,
  darkText: true
};

export const infoBlockData_2 = {
  id: 'info_block_data_2',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  contentFloat: ContentFloat.right,
  headerFloat: ContentFloat.right,
  shapePos: InfoBlockBgLinePosition.middle,
  textSize: TextSize.middle,
  topLine: '',
  headline: 'Mauris eu dignissim vehicula',
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus tempus nisl ut suscipit tempus. Vestibulum dolor nunc, ultrices ac pretium euismod, efficitur at felis. Ut placerat pulvinar dui. Fusce quis lacinia est. Duis venenatis urna a ultrices efficitur. Vestibulum luctus quis elit in luctus. Suspendisse efficitur nec odio eget rutrum. Sed rutrum nibh volutpat volutpat malesuada. Donec eu semper sapien. Suspendisse eu suscipit mauris.',
  img: require('../images/face-to-face-bro-people.svg'),
  imgSourceHref: 'https://storyset.com/business',
  imgSourceTitle: 'Business illustrations by Storyset',
  imgSourceIntro: 'illustration by storyset',
  imgSourceColor: 'on-light',
  imgSourceLocation: 'inline',
  imgSourceAlignment: 'bottom-center',
  imgSourceVerticalOffset: -8,
  imgSourceShowIcon: false,
  alt: 'Mauris eu dignissim vehicula',
  dark: false,
  primary: true,
  darkText: true
};