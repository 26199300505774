import { ContentFloat, InfoBlockBgLinePosition, TextSize } from '../types';
import { COMPANY_PHONE_ALT } from '../shared/globals';

export const heroSlideMain = {
  id: 'slide_buying_homes',
  lightBg: false,
  lightText: false,
  lightTextDesc: false,
  topLine: '',
  headline: 'Need To Sell Your House Quickly?',
  description: 'Sell your house as-is without any fees at your convenience with our easy and effective strategy.',
  buttonLabel: 'Learn More',
  imgStart: false,
  img: require('../images/for-sale-pana-building.svg'),
  imgOverlay: true,
  imgOverlayTransparency: 0.3,
  imgSourceHref: 'https://storyset.com/business',
  imgSourceTitle: 'Business illustrations by Storyset',
  imgSourceIntro: 'illustration by storyset',
  imgSourceColor: 'on-light',
  imgSourceLocation: 'relative',
  imgSourceAlignment: 'bottom-center',
  imgSourceVerticalOffset: 0,
  imgSourceShowIcon: false,
  bgColor: '',
  alt: 'Learn More',
  dark: true,
  primary: true,
  darkText: true,
  transparent: true,
  inverted: false,
  to: 'reason-to-sell'
};

export const heroSlideMain_ = {
  id: 'slide_buying_homes',
  lightBg: false,
  lightText: true,
  lightTextDesc: false,
  topLine: '',
  headline: 'Want To Sell Your House Fast?',
  description: 'We are a real estate investment company that specializes in making the sale of your home fast and easy. We have helped many homeowners in different states to sell their homes quickly and in “as-is” condition.',
  buttonLabel: 'Learn more',
  imgStart: false,
  img: 'iStock-1277551965.jpg',
  imgOverlay: true,
  imgOverlayTransparency: 0.3,
  alt: '',
  dark: false,
  primary: true,
  darkText: false,
  transparent: true,
  inverted: false
};

export const sectionFive = {
  id: 'section_1',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  contentFloat: ContentFloat.left,
  headerFloat: ContentFloat.left,
  shapePos: InfoBlockBgLinePosition.half,
  textSize: TextSize.middle,
  topLine: '',
  headline: 'Still Have Questions?',
  description: `If you still have any questions, please call us at ${ COMPANY_PHONE_ALT } or use “Contact Us” form to send your questions. Look forward to hearing from you.`,
  dark: false,
  primary: true,
  darkText: true
};